import {IUserDetails} from "@/components/account/models";
import {IUserInfoModel} from "@/components/admin/models";
import EventHandlers from "@/services/handlers";
import http from "@/services/http";

import {getTranslator} from "@translate/Provider";
import {intl2Str} from "@translate/T";

interface IEventHandler {
    namesChanged?(): void;
}

class DisplayNameService {
    private readonly handlers = new EventHandlers<IEventHandler>();

    private _userDetails = new Map<string, IUserDetails>();

    public get default() {
        return intl2Str(getTranslator(), "Anonymous");
    }

    public get system() {
        return intl2Str(getTranslator(), "System");
    }

    public async getDisplayName(user: string) {
        const _default = this.getOnlyDefault(user);
        if (typeof _default === "string") {
            return _default;
        }

        return (await this.getUserDetails(user))?.displayName ?? this.default;
    }

    // do not use lightly. can be undefined alot
    public getFastDisplayName(user: string) {
        const _default = this.getOnlyDefault(user);
        if (typeof _default === "string") {
            return _default;
        }

        return this._userDetails.get(user)?.displayName ?? this.default;
    }

    // use this carefuly, as it will not look into backend usernames
    public getOnlyDefault(user: IUserInfoModel | string) {
        if (!user) {
            return this.default;
        }

        if (user === "~system") {
            return this.system;
        }

        if (typeof user !== "string") {
            return user.displayName;
        }

        if (user.startsWith("~")) {
            return user; // we do not want to show Anonymous, if we have some user infos
        }

        return undefined;
    }

    // if you need fully new userDetails, display.delete them first
    public async getUserDetails(user: string) {
        return this._userDetails.get(user) ?? (await this.retrieve(user));
    }

    // clear this cache if we delete user
    public delete(user: string) {
        this._userDetails.delete(user);
    }

    // clear full cache
    public deleteAll() {
        this._userDetails.clear();
    }

    public subscribe(handler: IEventHandler) {
        return this.handlers.register(handler);
    }

    private async retrieve(user: string) {
        try {
            const response = await http
                .get("/api/users/" + user)
                .json<IUserDetails>();

            this._userDetails.set(user, response);
            this.handlers.publish((x) => x.namesChanged?.());

            return response;
        } catch {
            return undefined;
        }
    }
}

const display = new DisplayNameService();
export default display;
