import React from "react";
import {Link} from "react-router-dom";

import display from "@/services/display-name";
import {IProjectDocumentModel} from "./models";

import {getDocumentUrl} from "@/components/project/import/FileStatus";

interface IDocumentLinkProps {
    idSuffix: string;
    value: IProjectDocumentModel;

    textDotOverflow?: boolean;
}

class DocumentLink extends React.PureComponent<IDocumentLinkProps> {
    public render() {
        const url = this.getUrl();
        const {
            idSuffix,
            value: {id, name},
            textDotOverflow,
        } = this.props;

        if (!url || !name) {
            if (textDotOverflow) {
                return (
                    <em className="d-flex text-muted">
                        <span className="text-dot-overflow">
                            {!name ? display.default : name}
                        </span>
                    </em>
                );
            }

            return (
                <em className="text-muted">{!name ? display.default : name}</em>
            );
        }

        if (textDotOverflow) {
            return (
                <Link
                    id={id + "-" + idSuffix}
                    data-testid={id + "-" + idSuffix}
                    className="d-flex"
                    to={url}
                >
                    <span className="text-dot-overflow">{name}</span>
                </Link>
            );
        }

        return (
            <Link
                id={id + "-" + idSuffix}
                data-testid={id + "-" + idSuffix}
                to={url}
            >
                {name}
            </Link>
        );
    }

    private getUrl() {
        const {id, project, type, deviceClass} = this.props.value;

        if (!id || id.startsWith("~")) {
            return undefined;
        }

        return getDocumentUrl(id, project ?? 0, type, deviceClass);
    }
}

export default DocumentLink;
